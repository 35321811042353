<template>
  <el-select placeholder="请选择是否零散" clearable  class="w200"  v-bind="attrs"
             :model-value="modelValue" @change="changeScattered">
    <el-option v-for="item in scatteredList" :key="item.value"  :label="item.label" :value="item.value"  />
  </el-select>
</template>

<script setup>
import {toRefs, useAttrs} from "vue";

const props = defineProps({
  modelValue:{
    type: [String, Number],
    default:''
  }
})
const attrs = useAttrs()
const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue', 'change'])
//拆零列表
const scatteredList =[
  {
    label:'零散',
    value: 1
  },
  {
    label:'整装',
    value: 0
  },
  {
    label:'全部',
    value: -1
  }
]
const changeScattered = (val)=>{
  emit('update:modelValue',val)
  emit('change', val)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "scatteredSelect",
});
</script>

<style lang="scss" scoped>

</style>
