<template>
  <div class="tabList" v-bind="attrs" ref="tabRef">
    <slot></slot>
  </div>
</template>

<script setup>
import {getDomHeight} from "@utils/tool/getDomHeight";
import {computed, nextTick, onMounted, ref, useAttrs} from "vue";

const attrs = useAttrs();
const tabRef = ref(null)
const mt = ref(0)
const {domHeight} = getDomHeight('.queryBoxOne');
const {domHeight: cenSusDomHeight} = getDomHeight('.census');
const tableHeight = computed(() => {
  const h1 = +domHeight.value.split('px')[0] ? +domHeight.value.split('px')[0] + 16 : 0;
  const h2 = +cenSusDomHeight.value.split('px')[0] || 0
  return h1 + +h2 + mt.value + 4 + 'px'
})
onMounted(() => {
  nextTick().then(() => {
    if(tabRef.value){
      mt.value = +getComputedStyle(tabRef.value).marginTop.split('px')[0]
    }
  })
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "tabList",
});
</script>

<style lang="scss" scoped>
.tabList {
  margin-top: 20px;
  height: calc(100% - v-bind(tableHeight));
}
</style>
