<template>
  <div class="InventoryWarning" v-loading="loading">
    <div class="census">
      <div class="item">
        <div class="title">需补货产品</div>
        <div class="num">{{ census.needReplenishment }}<span class="unit">种</span></div>
      </div>
      <div class="item">
        <div class="title">可用库存总量</div>
        <div class="num">
         {{ census.adequate }}
        </div>
      </div>
    </div>
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select"
                   @change="getList" v-model="formData.stockId">
          <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
        </el-select>
        <el-button class="zh-btn" @click='openConfig'>预警规则配置</el-button>
      </div>
      <div class="queryItem">
        <el-input
        v-elInput
          v-model="formData.query"
          class="w240"
          placeholder="产品名称/简称/条码"
          @keydown.enter="getList"
          clearable
        />
        <SelectByAbcModal
          typeName="brand"
          :maxNum="1"
          v-model:list="formData.brand"
          className="w240 mr_16 "
          @change="getList"
          placeholder="产品品牌"
        ></SelectByAbcModal>
        <el-cascader
          v-model="formData.classifyId"
          :options="typeOptions"
          :props="candiyConfig"
          placeholder="产品分类"
          @change="getList"
          class="w240 mr_16"
          clearable
          filterable
        >
        </el-cascader>
        <!-- <el-select  v-model="formData.followTimeStatus" @change="getList" class="w120">
          <el-option    :value="-1" label="全部"></el-option>
          <el-option :value="1" label="关注效期"></el-option>
          <el-option :value="0" label="不关注效期"></el-option>
        </el-select> -->
        <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </div>
    <table-list class="tabList">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="clickOrderDetail"
        height="100%"
        @sort-change="changeSort"
        class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
       <template v-for="item in titleList" :key="item.fieldKey">
          <template v-if="item.isShow">
             <el-table-column
                 v-if="item.fieldKey === 'img'"
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
          >
            <template #default="scope">
               <el-popover placement="right" :width="400" trigger="hover"  popper-class='popImg'>
                   <template #reference>
                    <img :src="scope.row.img"  class="img"/>
                  </template>
                  <img :src="scope.row.img"  class="imgBig"/>
                </el-popover>
            </template>
          </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'followTime'"
              label="关注效期"
              align="center"
              width="120"
              :class-name="item.className || 'table_text2'"
            >
              <template #default="scope">
                {{ scope.row.followTime?'关注':'不关注' }}
              </template>
            </el-table-column>
               <el-table-column
                   v-else-if="item.fieldKey === 'encoded'"
            align="center"
            :label="item.title"
            :class-name="item.className || 'table_text2'"
            width="200"
          >
            <template #default="scope">
              <div class="moreListContent">
                 <more-list :list='scope.row.encoded'></more-list>
              </div>
            </template>
          </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'availableStock'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row[item.fieldKey] + '' || '--' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'scatteredName'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="{row}">
                <div v-html="getUnitName(row)"></div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :prop="item.fieldKey"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row[item.fieldKey] || '--' }}
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </table-list>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <FieldsCheck
      :list="titleList"
      v-model="customFields"
      @close="checkFileds"
    />
    <Inventroy  v-model="inventroyShow" title="库存详情" :stock-id="formData.stockId" :goodsInfo='cheooseGoodsInfo' @close="inventroyShow = false"></Inventroy>
    <InventoryWarningConfig v-model='configShow' @close="configShow = false"
                            :stock-list="sotckList"></InventoryWarningConfig>
  </div>
</template>
<script setup>
import {removeNoChild, tableRowClassName} from "@/utils/tool.js";
import InventoryWarningConfig from './component/inventoryWarningConfig.vue'
import MoreList from '@/components/moreList/moreList.vue';
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {useStore} from "vuex";
import Inventroy from './component/inventroy.vue'
import {columnsStockWarning} from "./config.js";
import {useRoute, useRouter} from "vue-router";
import {cloneDeep} from "lodash";
import {stockList} from '@/utils/api/procurement/stock.js'
import {typeInfo} from "@/utils/api/type.js";
import {stockAlarmDetail, stockAlarmList, stockAlarmLook} from '@/utils/api/inStock/inventory.js'
import {getDomHeight} from "@utils/tool/getDomHeight";
import TableList from "@components/element/tableList/index.vue";
import {getUnitName} from "@utils/tool/orderTools";

const {domHeight} = getDomHeight('.queryBoxOne');
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const store = useStore();
const loading = ref(false);
const showDetail = ref(false);
const inventroyShow = ref(false)
const slowSalesShow = ref(false)
const configShow = ref(false)
//控组详情弹窗是否展示
const orderShow = ref(false);
const orderInfoRefs = ref(null);
const cheooseGoodsInfo = ref({});
//打开规则预警配置弹窗
const openConfig = () => {
  configShow.value = true
}
//打开订单详情弹窗
const clickOrderDetail = async (row) => {
  stockAlarmLook({
      productId: row.productId,modelId:row.modelId,alarmType:1,
    stockId:formData.stockId
  })
  let res = await stockAlarmDetail({ productId: row.productId,modelId:row.modelId,alarmType:1,  stockId:formData.stockId });
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  cheooseGoodsInfo.value = res.data;
  inventroyShow.value = true;
};
/*
  关于统计
*/
const census = reactive({
  needReplenishment: 0, //需要补货的数量
  adequate: 0, //库存充足
});
const formData = reactive({
  query: "",
  followTimeStatus:-1,
  supplier: [],
  brand:[],
  brandId:'',
  stockId:""
});
const pageInfo = reactive({
  pageSize: 20,
  pageNumber: 1,
  total: 0,
});
  const candiyConfig = {
      expandTrigger: "hover",
      value: "classifyId",
      label: "classifyName",
      children: "childList",
      checkStrictly: true,
    };
    const handleInventroy = ()=>{
  inventroyShow.value = true
}
const handleSlowSales = ()=>{
  slowSalesShow.value = true
}
//获取分类列表
const getTypeList = async () => {
  let res = await typeInfo({});
  if (res.code != 0) {
    proxy.$message.error(res.msg);
    return;
  }
  let list = res.data?.list || []
  //排除掉list 里面不包含childList的数据
  list = removeNoChild(list);
  typeOptions.value = list;
};
const typeOptions = ref([]);
const sotckList = ref([])
const titleList = ref(cloneDeep(columnsStockWarning));
const titleListForCheck = ref(cloneDeep(columnsStockWarning));
const tableData = ref([]);
const customFields = ref(false);
const fieldCheckShow = () => {
  customFields.value = true;
};
const checkFileds = (list) => {
  customFields.value = false;
};
const getList = () => {
  loading.value = true;
  let obj = {
    followTimeStatus: formData.followTimeStatus,
    brandId: formData.brand[0]?.brandId || "",
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: formData.sortField,
    sortType: formData.sortType,
    classifyId:formData.classifyId?.length > 0?formData.classifyId[formData.classifyId.length - 1]:'',
    alarmType:1,
    stockId:formData.stockId
  };
  stockAlarmList(obj)
    .then((res) => {
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data?.list || [];
      pageInfo.total = res?.data?.total || 0;
      census.needReplenishment = res.data?.census?.needReplenishment || 0;
      census.adequate = res.data?.census?.adequate || 0;
      setTimeout(() => {
        loading.value = false;
      }, 100);
    })
    .catch((err) => {})
    .finally(()=>{
      loading.value = false;
    })
};
const changeSort = ({ column, prop, order }) => {
  if (!order) {
    formData.sortField = "";
    formData.sortType = "";
  } else {
    formData.sortField = prop;
    formData.sortType = order == "descending" ? "desc" : "asc";
  }
  getList();
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
//获取仓库列表
const getStockListData = async () => {
  const res = await stockList({
    all: false,
    status: true
  })
  if (res.code === 0) {
    if (res.data?.list?.length) {
      formData.stockId = res.data.list[0].stockId
      sotckList.value = res.data.list
    }
    getList();
  }else{
    proxy.$message.error(res.msg)
  }
}
onMounted(() => {
  getStockListData()
  getTypeList()
});

</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "InventoryWarning",
  components: {
    Search,
    Plus,
    FieldsCheck,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.InventoryWarning {
  padding-top: 8px;
  height: calc(100% - 54px);
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .imgList {
    img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 5px;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }
      .more {
        color: #73737f;
      }
    }
    .statusBtn {
      display: flex;
      justify-content: center;
    }
    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;
      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}
</style>
