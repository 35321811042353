//库存列表表头
export const columnsStock = [
  {
    title:"产品图片",
    fieldKey:"img",
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品名称",
    fieldKey:"productName",
    width:'200',
    isShow:true,
    className:'table_text2'
  },
  {
    title:"简称",
    fieldKey:"subName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品条码",
    fieldKey:"encoded",
    width:'140',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品单位",
      fieldKey: "unitName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"规格/型号",
    fieldKey:"modelName",
    width:'150',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品品牌",
    fieldKey:"brandName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品分类",
    fieldKey:"classifyName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"正常库存",
    fieldKey:"normalStock",
    width:'150',
    isShow:true,
    className:'table_text',
    sortable:true,
  },
  {
    title:"合计金额(元)",
    fieldKey:"totalPrice",
    width:'150',
    isShow:true,
      className: 'table_text2',
    sortable:true,
  },
  {
    title:"预估销售周期(天)",
    fieldKey:"estimatedSalesCycle",
    width:'200',
    isShow:true,
    className:'table_text',
    sortable:true,
  },
  {
    title:"异常库存",
    fieldKey:"abnormalStock",
    width:'120',
    isShow:true,
    className:'table_text',
    sortable:true,
  },
  {
    title:"异常金额(元)",
    fieldKey:"abnormalPrice",
    width:'150',
    isShow:true,
      className: 'table_text2',
    sortable:true,
  },
  {
    title:"最后盘点时间",
    fieldKey:"lastTime",
    width:'150',
    isShow:true,
    className:'table_text',
    sortable:true,
  },
]
//库存预警表头
export const columnsStockWarning = [
  {
    title:"产品图片",
    fieldKey:"img",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品名称",
    fieldKey:"productName",
    width:'200',
    isShow:true,
    className:'table_text2'
  },
  {
    title:"简称",
    fieldKey:"subName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品条码",
    fieldKey:"encoded",
    width:'140',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品单位",
    fieldKey:"unitName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"规格/型号",
    fieldKey:"modelName",
    width:'150',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品品牌",
    fieldKey:"brandName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品分类",
    fieldKey:"classifyName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"可用库存",
    fieldKey:"availableStock",
    width:'150',
    isShow:true,
    className:'table_text',
    sortable:true,
  },
  // {
  //   title:"预估销售周期(天)",
  //   fieldKey:"estimatedSalesCycle",
  //   width:'200',
  //   isShow:true,
  //   className:'table_text',
  // },

]

//效期预警表头
export  const columnsTimeWarning =  [
  {
    title:"产品图片",
    fieldKey:"img",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品名称",
    fieldKey:"productName",
    isShow:true,
    className:'table_text2'
  },
  {
    title:"简称",
    fieldKey:"subName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品条码",
    fieldKey:"encoded",
    width:'140',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品单位",
    fieldKey:"unitName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"规格/型号",
    fieldKey:"modelName",
    width:'150',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品品牌",
    fieldKey:"brandName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品分类",
    fieldKey:"classifyName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
      title: "临期标签数量",
    fieldKey:"totalNum",
    width:'150',
    isShow:true,
    className:'table_text',
  },
  {
    title:"合计金额（元）",
    fieldKey:"totalPrice",
    width:'200',
    isShow:true,
      className: 'table_text2',
  }
]

//滞销预警表头
export  const columnsValidity = [
  {
    title:"产品图片",
    fieldKey:"img",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品名称",
    fieldKey:"productName",
    isShow:true,
    className:'table_text2'
  },
  {
    title:"简称",
    fieldKey:"subName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品条码",
    fieldKey:"encoded",
    width:'140',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品单位",
    fieldKey:"unitName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"规格/型号",
    fieldKey:"modelName",
    width:'150',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品品牌",
    fieldKey:"brandName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
    title:"产品分类",
    fieldKey:"classifyName",
    width:'120',
    isShow:true,
    className:'table_text'
  },
  {
      title: "滞销标签数量",
    fieldKey:"totalNum",
    width:'150',
    isShow:true,
    className:'table_text',
  },
  {
    title:"合计金额（元）",
    fieldKey:"totalPrice",
    width:'200',
    isShow:true,
    className:'table_text',
  },
]

export const stockTypeList = [
  {
    label: "全部库存",
    value: -1,
  },
  {
    label: "正常库存",
    value:1,
  },
  {
    label: "异常库存",
    value: 2,
  },
  {
    label: "整装库存",
    value: 3,
  },
  {
    label: "零散库存",
    value: 4,
    },
]
