import request from '../http'
import {managerUrl} from '../api'
//分类信息

//产品分类信息
export function typeInfo(data){
  return request({
    url:managerUrl + "/carton/product/classify/info",
    method:'post',
    data
  })
}

//查询分类已绑定商品列表
export function typeProductList(data){
  return request({
    url:managerUrl + "/carton/product/classify/product",
    method:'post',
    data
  })
}

/*
* 查询未分类产品列表
* @param {string} query 产品搜索信息
* @param {number} brandId 品牌id
*/
export function typeProductListByUnProduct(data){
  return request({
    url:managerUrl + "/carton/product/classify/un/product",
    method:'post',
    data
  })
}


/*
* 分类绑定产品
* @param {string} query 产品搜索信息
* @param {number} classifyId 分类id
*/
export function typeProductListByClassifyBind(data){
  return request({
    url:managerUrl + "/carton/product/classify/bind",
    method:'post',
    data
  })
}

//产品分类新增
export function typeAdd(data){
  return request({
    url:managerUrl + "/carton/product/classify/add",
    method:'post',
    data
  })
}

//产品分类修改
export function typeEdit(data){
  return request({
    url:managerUrl + "/carton/product/classify/edit",
    method:'post',
    data
  })
}

//产品分类删除
export function typeDel(data){
  return request({
    url:managerUrl + "/carton/product/classify/del",
    method:'post',
    data
  })
}


//无分类列表
export function classifyProductList(data){
  return request({
    url:managerUrl + "/carton/product/classify/product",
    method:'post',
    data
  })
}

//修改上级分类
export function classifyChangeParent(data) {
  return request({
    url: managerUrl + "/carton/product/classify/change/parent",
    method: 'post',
    data
  })
}

