import resetMessage from '@utils/tool/message';

let errListTimer = null

export function checkErrList(list = [], checkKey = 'count', lastNull = true) {
    if (errListTimer) clearTimeout(errListTimer)
    let isErr = false
    list.forEach((item, index) => {
        if ((!item[checkKey] || item[checkKey] === 0 || item[checkKey] === '0') && index < list.length - 1) {
            item.isErr = true
            isErr = true
        }
    })
    if (isErr) {
        resetMessage.error('请先填写必填选项')
        if (list.some((item) => item.isErr)) {
            errListTimer = setTimeout(() => {
                list.forEach((item) => {
                    item.isErr = false
                })
                resetMessage.close()
            }, 1000)
        }
    }
    return isErr
}