<template>
  <el-autocomplete
      v-model="formData.childShelfName"
      :debounce="500"
      :fetch-suggestions="querySearchAsync"
      :placeholder="placerholder.childShelfId"
      @select="handleSelect"
      :trigger-on-focus="false"
      clearable
      @change="handChange"
      :disabled="!stockId"
  />
</template>

<script setup>
import {reactive, toRefs, watchEffect} from "vue";
import message from "@utils/tool/message";
import {getShelfPosition} from "@/utils/api/shelf";
import placerholder from '@/utils/config/placerholder'

const props = defineProps({
  stockId: {
    type: [String, Number],
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  }
})
const {modelValue, stockId} = toRefs(props)
const emit = defineEmits(['update:modelValue', 'onChange'])
const formData = reactive({
  childShelfName: ''
})

let controller = null;
const querySearchAsync = async (queryString, cb) => {
  controller && controller?.abort()
  controller = new AbortController()
  if (!queryString) {
    return
  }
  const {data, msg, code} = await getShelfPosition({stockId: stockId.value, childShelfName: formData.childShelfName})
  if (code !== 0) {
    message.error(msg)
    return
  }
  data.list = data?.list || []
  cb(data.list.map(item => ({value: item.childShelfName, childShelfId: item.childShelfId})))
}

const handleSelect = (item) => {
  if (item?.childShelfId) {
    emit('update:modelValue', item.childShelfId)
    emit('onChange', item)
  }
}

watchEffect(() => {
  if (!modelValue.value) {
    formData.childShelfName = ''
  }
})

const handChange = (val) => {
  if (!val) {
    emit('update:modelValue', '')
    emit('onChange', '')
  }
}

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "SearchShelfInput",
});
//表单搜索货架input自动补全
</script>

<style lang="scss" scoped>

</style>
