import {reactive} from 'vue';
import filter from '@/utils/filter.js'

export const useCensusList = (list) => {
    const censusList = reactive(list);
    const getCensus = (data) => {
        for (const cen of censusList) {
            if (cen.unit) {
                cen.value = filter.toYuan(data?.census?.[cen.key] || 0, cen.unit || "元");
            } else {
                cen.value = data?.census?.[cen.key] || 0;
            }
        }
    }
    return {
        censusList,
        getCensus
    };
}