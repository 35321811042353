<template>
  <div class="slowSalesWarning" v-loading="loading">
    <div class="census">
      <div class="item">
        <div class="title">预警产品</div>
        <div class="num">{{ census.warning }}<span class="unit">种</span></div>
      </div>
      <div class="item">
        <div class="title">合计数量</div>
        <div class="num">
          {{ census.totalNum }}
        </div>
      </div>
      <div class="item">
        <div class="title">合计金额</div>
        <div class="num">
          {{ $filters.toYuan(census.totalPrice, "元") }}
          <span class="unit">元</span>
        </div>
      </div>
    </div>
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select"
                   @change="getList" v-model="formData.stockId">
          <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
        </el-select>
        <el-button class="zh-btn" @click="configShow = true">效期预警配置</el-button>
      </div>
      <div class="queryItem">
        <el-input
        v-elInput
          v-model="formData.query"
          class="w240"
          placeholder="产品名称/简称/条码"
          @keydown.enter="getList"
          clearable
        />
        <SelectByAbcModal
          typeName="brand"
          :maxNum="1"
          v-model:list="formData.brand"
          className="w240 mr_16 "
          @change="getList"
          placeholder="产品品牌"
        ></SelectByAbcModal>
        <el-cascader
          v-model="formData.classifyId"
          :options="typeOptions"
          :props="candiyConfig"
          placeholder="产品分类"
          @change="getList"
          class="w240 mr_16"
          clearable
          filterable
        >
        </el-cascader>
        <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </div>
    <table-list>
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="clickOrderDetail"
        height="100%"
        @sort-change="changeSort"
        class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleList" :key="item.fieldKey">
          <template v-if="item.isShow">
            <el-table-column
                v-if="item.fieldKey === 'img'"
                align="center"
                :prop="item.fieldKey"
                :label="item.title"
            >
              <template #default="scope">
                <el-popover placement="right" :width="400" trigger="hover"  popper-class='popImg'>
                  <template #reference>
                    <img :src="scope.row.img" class="img" alt="位元灵感"/>
                  </template>
                  <img :src="scope.row.img" alt="位元灵感" class="imgBig"/>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'followTime'"
                label="关注效期"
                align="center"
                width="120"
                :class-name="item.className || 'table_text2'"
            >
              <template #default="scope">
                {{ scope.row.followTime?'关注':'不关注' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'encoded'"
                align="center"
                :label="item.title"
                :class-name="item.className || 'table_text2'"
                width="200"
            >
              <template #default="scope">
                <div class="moreListContent">
                  <more-list :list='scope.row.encoded'></more-list>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'warningStatus'"
                align="center"
                :label="item.title"
                width="200"
                class-name="error_type"
            >
              <template #default="scope">
               <div class="error_type" >
                 <div class="err_type" :class="scope.row.warningStatus == 1?'status2':'status2'">
                   {{scope.row.warningStatusDesc}}
                 </div>
                 </div>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'totalPrice'"
                align="center"
                :label="item.title"
                width="200"
                class-name="error_type"
            >
              <template #default="scope">
                {{ $filters.toYuan(scope.row.totalPrice, '元') }}
              </template>
            </el-table-column>
            <el-table-column
                v-else
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{scope.row[item.fieldKey] || '--'}}
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </table-list>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <FieldsCheck
      :list="titleList"
      v-model="customFields"
      @close="checkFileds"
    />
    <SlowSales :goodsInfo='cheooseGoodsInfo' :stock-id="formData.stockId" value-string="剩余过期时间（天）"
               :alarmType='alarmType' title="查看明细" v-model="slowSalesShow" :scatter="-1"></SlowSales>
    <SlowConfig v-model='configShow' @close="configShow = false" :stock-list="sotckList"></SlowConfig>
  </div>
</template>
<script setup>
import {removeNoChild, tableRowClassName} from "@/utils/tool.js";
import SlowConfig from './component/slowConfig.vue'
import MoreList from '@/components/moreList/moreList.vue';
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {useStore} from "vuex";
import SlowSales from './component/slowSales.vue'
import {columnsTimeWarning} from "./config.js";
import {useRoute, useRouter} from "vue-router";
import {cloneDeep} from "lodash";
import {stockList} from '@/utils/api/procurement/stock.js'
import {typeInfo} from "@/utils/api/type.js";
import TableList from "@components/element/tableList/index.vue";
import {stockAlarmDetail, stockAlarmLook, stockAlarmTime} from "@/utils/api/inStock/inventory";

const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const store = useStore();
const loading = ref(false);
const showDetail = ref(false);
const inventroyShow = ref(false)
const slowSalesShow = ref(false)
const configShow = ref(false)
const cheooseGoodsInfo = ref({})
//控组详情弹窗是否展示
const orderShow = ref(false);
const orderInfoRefs = ref(null);
const alarmType = ref(2)
//打开订单详情弹窗
const clickOrderDetail = async (row) => {
  stockAlarmLook({
    productId: row.productId,modelId:row.modelId,alarmType:alarmType.value,
    stockId:formData.stockId
  })
  let res = await stockAlarmDetail({
    productId: row.productId,
    modelId: row.modelId,
    alarmType: alarmType.value,
    stockId: formData.stockId,
    scattered: -1
  });
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  cheooseGoodsInfo.value = Object.assign(res.data, {scattered: -1})
  slowSalesShow.value = true;
};
/*
  关于统计
*/
const census = reactive({
  totalNum: 0,
  totalPrice: 0,
  warning: 0,
});
const formData = reactive({
  query: "",
  supplier: [],
  brand:[],
  brandId:"",
  supplierId:'',
  status:"0",
  stockId:''
});
const pageInfo = reactive({
  pageSize: 20,
  pageNumber: 1,
  total: 0,
});
  const candiyConfig = {
      expandTrigger: "hover",
      value: "classifyId",
      label: "classifyName",
      children: "childList",
      checkStrictly: true,
    };
//获取分类列表
const getTypeList = async () => {
  let res = await typeInfo({});
  if (res.code != 0) {
    proxy.$message.error(res.msg);
    return;
  }
  let list = res.data?.list || []
  //排除掉list 里面不包含childList的数据
  list = removeNoChild(list);
  typeOptions.value = list;
};
const typeOptions = ref([]);
const sotckList = ref([])
const titleList = ref(cloneDeep(columnsTimeWarning));
const titleListForCheck = ref(cloneDeep(columnsTimeWarning));
const tableData = ref([]);
const customFields = ref(false);
const fieldCheckShow = () => {
  customFields.value = true;
};
const checkFileds = (list) => {
  customFields.value = false;
};
const getList = () => {
  loading.value = true;
  let obj = {
    brandId: formData.brand[0]?.brandId || "",
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: formData.sortField,
    sortType: formData.sortType,
    stockId:formData.stockId,
    classifyId:formData.classifyId?.length > 0?formData.classifyId[formData.classifyId.length - 1]:'',
  };
  stockAlarmTime(obj)
    .then((res) => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data?.list || [];
      pageInfo.total = res?.data?.total || 0;
      census.totalNum = res.data?.census?.totalNum || 0;
      census.totalPrice = res.data?.census?.totalPrice || 0;
      census.warning = res.data?.census?.warning || 0;
      setTimeout(() => {
        loading.value = false;
      }, 100);
    })
    .catch((err) => {})
    .finally(()=>{
      loading.value = false;
    })
};
const changeSort = ({ column, prop, order }) => {
  if (!order) {
    formData.sortField = "";
    formData.sortType = "";
  } else {
    formData.sortField = prop;
    formData.sortType = order === "descending" ? "desc" : "asc";
  }
  getList();
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
  //获取仓库列表
  const getStockListData = async () => {
    const res = await stockList({
      all: false,
      status: true
    })
    if (res.code === 0) {
      if (res.data?.list?.length) {
        formData.stockId = res.data.list[0].stockId
        sotckList.value = res.data.list
      }
      getList();
      getList();
    }else{
      proxy.$message.error(res.msg)
    }
  }
onMounted(() => {
  getStockListData()
  getTypeList()
});
</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "slowSalesWarning",
  components: {
    Search,
    Plus,
    FieldsCheck,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.slowSalesWarning {
  padding-top: 8px;
  height: calc(100% - 54px);
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .imgList {
    img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 5px;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }
      .more {
        color: #73737f;
      }
    }
    .statusBtn {
      display: flex;
      justify-content: center;
    }
    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;
      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}

</style>
