<template>
  <el-button @click="handleClickExport" :disabled="disable">
    <export-img :class="disable?'disable':''"></export-img>
    <span class="ml_8">导出</span>
  </el-button>
  <el-dialog
      title="导出方式"
      width="500"
      class="export-inventory-check-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      align-center
      v-model="showModal"
  >
    <div class="export-inventory-check-dialog-content">
      <el-radio-group v-model="exportTypeNumber">
        <el-radio :label="1">按产品导出</el-radio>
        <el-radio :label="2">按标签导出</el-radio>
      </el-radio-group>
    </div>
    <template #footer>
      <el-button @click="handleCloseModal">取消</el-button>
      <el-button class="main" :loading="loading" type="primary" @click="handleExport">
        确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import ExportImg from "@components/exportImg.vue";
import {computed, provide, ref, toRefs ,getCurrentInstance} from "vue";
import useModal from "@/utils/hooks/useModal";
import {insStockList, stockExportSerial} from '@/utils/api/inStock/inventory.js'
import {resetStockGoodsList, filterPrice} from "@utils/tool";
import filter from "@/utils/filter";
import {columnsStock} from "@views/inStock/inventoryCheck/components/config";
import {tagsColumn} from "./config.js";
import ExportNew from '@utils/excel/export.js'
import message from "@utils/tool/message";
const loading = ref(false)

const {proxy} = getCurrentInstance()
const column = columnsStock.filter(item => item.fieldKey !== 'img')

const {showModal, handleOpenModal, handleCloseModal} = useModal()
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    }
  },
  listLength: {
    type: Number,
    default: 0
  }
})
const {formData, listLength} = toRefs(props)
const exportTypeNumber = ref(1)
const disable = computed(() => {
  return listLength.value === 0
})
provide('formData', formData)

const handleClickExport = () => {
  exportTypeNumber.value = 1
  handleOpenModal()
}
//按产品导出
const exportProduct = (obj) => {
  loading.value = true;
  insStockList({...obj,pageSize:10000,pageNumber:1}).then(res => {
    if (res.code !== 0) {
      message.error(res.msg)
      return
    }
    let resultList = res.data?.list || []
    resultList = resetStockGoodsList(resultList)
    resultList.forEach((item, index) => {
      item.lastTime = item.lastTime ? filter.timeFormat(item.lastTime, 1) : '--'
      item.index = index + 1
      filterPrice(item)
      if (item.scatteredName) {
        item.unitName = item.scattered ? item.scatteredName + "（零）" : item.scatteredName + "（整）"
      }
      item.realIndex++
    })
    resultList = setList(resultList)
    let titleList = column || []
    titleList = [{
      title: '序号',
      fieldKey: 'realIndex',
      sort: 0
    },...titleList]
    ExportNew.export2Excel(resultList, titleList, '库存列表', true, [0 ,1, 2, 3, 5, 6, 7, 10, 13])
    handleCloseModal()
    loading.value = false;
  })
}

const exportTag = (obj) => {
  loading.value = true;
  stockExportSerial({...obj,pageSize:10000,pageNumber:1}).then(res => {
    if (res.code !== 0) {
      message.error(res.msg)
      return
    }
    let resultList = res.data?.list || []
    resultList.forEach((item, index) => {
      item.index = index + 1
      if (item.scatteredName) {
        item.unitName = item.labelScattered ? item.scatteredName + "（零）" : item.unitName + "（整）"
      }
      item.inStockPrice = proxy.$filters.toYuan(item.inStockPrice, "元");
      item.inStockTime = proxy.$filters.timeFormat(item.inStockTime, 2);
      item.outTime = proxy.$filters.timeFormat(item.outTime, 2);
    })
    resultList = setList(resultList)
    let titleList = tagsColumn || []
    titleList = [{
      title: '序号',
      fieldKey: 'index',
      sort: 0
    },...titleList]
    ExportNew.export2Excel(resultList, titleList, '库存列表', true)
    handleCloseModal();
    loading.value = false;
  })
}

const setList = (list) => {
  let newArr = []
  if(formData.value.stockType === 3){
    //整装
    newArr = list.filter(item =>!item.scattered)
  }else if(formData.value.stockType === 4){
    //散装
    newArr = list.filter(item => !item.scattered)
  }else{
    newArr = list
  }
  return newArr
}


const handleExport = () => {
  const obj = formData.value
  if (exportTypeNumber.value === 1) {
    exportProduct(obj)
  } else if (exportTypeNumber.value === 2) {
    exportTag(obj)
  }
}

</script>

<style lang="scss" scoped>

</style>