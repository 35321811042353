<template>
  <div class="inventoryList" v-loading="loading">
    <order-census :census-list="censusList"></order-census>
    <form class="queryBoxOne" @submit.prevent="getList">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select"
                   @change="changeStock" v-model="formData.stockId">
          <el-option v-for="item in stockList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
        </el-select>
      </div>
      <div class="queryItem">
        <el-input
          v-model="formData.query"
          class="w240"
          placeholder="产品名称/简称/条码"
          clearable
          v-elInput
        />
        <search-shelf-input v-model="formData.childShelfId" class="w240" :stock-id="formData.stockId"
                            @on-change="getList"></search-shelf-input>
        <SelectByAbcModal
          typeName="brand"
          :maxNum="1"
          v-model:list="formData.brand"
          className="w240"
          @change="getList"
          placeholder="产品品牌"
        ></SelectByAbcModal>
          <SelectByAbcModal
          typeName="supplier"
          :maxNum="1"
          v-model:list="formData.supplier"
          className="w240 "
          @change="getList"
          placeholder="供应商名称"
        ></SelectByAbcModal>
        <el-select placeholder="请选择库存类型" class="w200" v-model="formData.stockType" @change="getList">
          <el-option v-for="item in stockTypeList" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
        <el-cascader
          v-model="formData.classifyId"
          :options="typeOptions"
          :props="candiyConfig"
          placeholder="产品分类"
          @change="getList"
          class="w240"
          clearable
          filterable
        >
        </el-cascader>
        <!--        <ScatteredSelect v-model="formData.scattered"/>-->
        <div class="queryBtn">
          <el-button type="primary" native-type="submit" class="square"
            ><el-icon><Search /></el-icon
          ></el-button>
          <export-btn :form-data="downFormData" :list-length="tableData.length"></export-btn>
          <el-button class="ml_8" @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </form>
    <table-list class="tabList">
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="clickOrderDetail"
        height="100%"
        @sort-change="changeSort"
        class="zh-no-table-border"
        :row-class-name="formData.stockType === 4 || formData.stockType === 3 ? tableRowClassName: tableRowClassNameNew"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
        :span-method="formData.stockType === 4 || formData.stockType === 3? objectSpanMethod([]) : objectSpanMethod([0,1,2,3,4,6,7,8,11,14])"
      >
        <el-table-column
          width="100"
          label="序号"
          align="center"
          fixed
        >
          <template #default="{row}">
            <div class="table_text">{{ row.realIndex + 1 }}</div>
          </template>
        </el-table-column>
        <template v-for="item in titleList" :key="item.fieldKey">
          <template v-if="item.isShow">
            <el-table-column
                v-if="item.fieldKey === 'img'"
                align="center"
                :prop="item.fieldKey"
                :label="item.title"
                :width="item.width"
                fixed
          >
            <template #default="scope">
              <el-popover placement="right" :width="400" trigger="hover" popper-class='popImg'>
                <template #reference>
                  <img :src="scope.row.img" alt="位元灵感" class="img"/>
                </template>
                <img :src="scope.row.img" alt="位元灵感" class="imgBig"/>
              </el-popover>
            </template>
          </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'followTime'"
              label="关注效期"
              align="center"
              width="120"
              :class-name="item.className || 'table_text2'"
            >
              <template #default="scope">
                {{ scope.row.followTime ? '关注' : '不关注' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'encoded'"
                align="center"
                :label="item.title"
                :class-name="item.className || 'table_text2'"
                width="200"
          >
            <template #default="scope">
              <div class="moreListContent">
                <more-list :list='scope.row.encoded'></more-list>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-else-if="item.fieldKey === 'estimatedSalesCycle'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="formData.stockId === '' ? false : true"
              :prop="item.fieldKey"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
          >
            <template #default="scope">
              {{ scope.row[item.fieldKey] || '--' }}
            </template>
          </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'lastTime'"
              align="center"
              :label="item.title"
              :width="item.width"
                :sortable="formData.stockId === '' ? false : true"
              :prop="item.fieldKey"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row[item.fieldKey] || '--' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'abnormalStock' || item.fieldKey === 'normalStock'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row[item.fieldKey] + '' || '--' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'estimatedSalesCycle'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row[item.fieldKey] || '0' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'unitName'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="{row}">
                <div v-html="getUnitName(row)"></div>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'totalPrice'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="{row}">
                <div>{{ $filters.toYuan(row.totalPrice, '元') }}</div>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'abnormalPrice'"
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
              <template #default="{row}">
                <div>{{ $filters.toYuan(row.abnormalPrice, '元') }}</div>
              </template>
            </el-table-column>
            <el-table-column
                v-else
                align="center"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :prop="item.fieldKey"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
                :fixed="item.fieldKey === 'productName' ? 'left' : false"
            >
              <template #default="scope">
                <div v-html="scope.row[item.fieldKey] || '--'"></div>
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </table-list>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <FieldsCheck
      :list="titleList"
      v-model="customFields"
      @close="checkFileds"
    />
    <InventoryDetail v-model="showDetail" :stockType="formData.stockType" :goodsInfo='cheooseGoodsInfo'
                     :child-shelf-id="formData.childShelfId"></InventoryDetail>
  </div>
</template>
<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import {objectSpanMethod, resetStockGoodsList,tableRowClassName  ,tableRowClassNameNew} from "@/utils/tool.js";
import {computed, getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {useStore} from "vuex";
import {columnsStock ,stockTypeList} from "./config.js";
import {insStockList, stockDetail} from '@/utils/api/inStock/inventory.js'
import {useRoute, useRouter} from "vue-router";
import InventoryDetail from "./component/inventoryDetail.vue"
import OrderCensus from "@components/orderCensus/index.vue";
import {useCensusList} from '@/utils/hooks/useCensus'
import {getDomHeight} from "@utils/tool/getDomHeight";
import TableList from "@components/element/tableList/index.vue";
import {usePagination} from "@utils/tool/page.js";
import useStock from "@utils/hooks/useStock";
import useClassify from "@utils/hooks/useClassify";
import useFieldCheck from "@utils/hooks/useFieldCheck";
import {getUnitName} from "@utils/tool/orderTools";
import ScatteredSelect from "@components/product/scatteredSelect/index.vue";
import ExportBtn from "./export/index.vue";
import SearchShelfInput from "@components/form/SearchShelfInput/index.vue";

// const stockName = computed(() => {
//   const stockName = stockList.value.find(item => item.stockId === formData.stockId)?.stockName
//   return stockName || ''
// })
const downFormData = ref({})
const {stockList, getStockListData} = useStock()
const {titleList, customFields, fieldCheckShow, checkFileds} = useFieldCheck(columnsStock)
const {domHeight} = getDomHeight('.queryBoxOne');
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const store = useStore();
const loading = ref(false);
const showDetail = ref(false);
//控组详情弹窗是否展示
const orderShow = ref(false);
const {censusList, getCensus} = useCensusList([
  {title: "正常库存", value: 0, key: 'normalStock'},
  {title: "产品金额（元）", value: 0, key: 'totalPrice', unit: "元"},
  {title: "异常库存", value: 0, key: 'abnormalStock'},
  {title: "异常金额（元）", value: 0, key: 'abnormalPrice', unit: "元"},
])
const {pageInfo} = usePagination()
const cheooseGoodsInfo = ref({});
const {typeOptions, getTypeList, candiyConfig} = useClassify()


//打开订单详情弹窗
const clickOrderDetail = async (row) => {
  let res = await stockDetail({
    productId: row.productId,
    modelId: row.modelId,
    stockId: formData.stockId,
    scattered: row.scattered,
    stockType: formData.stockType,
    childShelfId: formData.childShelfId
  })
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  cheooseGoodsInfo.value = Object.assign({stockId: formData.stockId, scattered: row.scattered}, res.data);
  showDetail.value = true;
};
/*
  关于统计
*/
const census = reactive({
  normalStock: 0,
  totalPrice: 0,
  abnormalStock: 0,
  abnormalPrice:0
});
const formData = reactive({
  query: "",
  classifyId:"",
  supplier: [],
  brand:[],
  followTimeStatus:-1,
  stockId:'',
  scattered:"",
  childShelfId: "",
  stockType: -1,
});
const tableData = ref([]);
const changeStock = () => {
  formData.childShelfId = ''
  getList()
}
const getList = () => {
  loading.value = true;
  let sortField ;
  const prop = formData.sortField;
  if(prop === 'totalPrice'){
    sortField = 'sortPrice'
  }else if(prop === 'abnormalPrice'){
    sortField = 'sortAbnormalPrice'
  }else if(prop === 'normalStock'){
    sortField = formData.stockType === 4 ? "scatteredNormalStock" : "normalStock"
  }else if(prop === 'abnormalStock'){
    sortField = formData.stockType === 4 ? "scatteredAbnormalStock" : "abnormalStock"
  }
  let obj = {
    followTimeStatus: formData.followTimeStatus,
    supplierId: formData.supplier[0]?.supplierId || "",
    brandId: formData.brand[0]?.brandId || "",
    stockId:formData.stockId || '',
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: sortField,
    sortType: formData.sortType,
    scattered: formData.scattered,
    childShelfId: formData.childShelfId,
    stockType: formData.stockType,
    classifyId:formData.classifyId?.length > 0?formData.classifyId[formData.classifyId.length - 1]:''
  };
  downFormData.value = obj
  insStockList(obj)
    .then((res) => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data?.list || []
      list = resetStockGoodsList(list, (item) => {
        item.lastTime = item.lastTime ? proxy.$filters.timeFormat(item.lastTime, 1) : '--'
      })
      if(formData.stockType === 4){
        list = list.filter(item =>item.scattered)
      }
      if(formData.stockType === 3){
        list = list.filter(item =>!item.scattered)
      }
      tableData.value = list;
      pageInfo.total = res?.data?.total || 0;
      getCensus(res.data)
      proxy.$emit("change");
    })
    .finally(()=>{
      loading.value = false;
    })
};
const changeSort = ({prop, order}) => {
  if (!order) {
    formData.sortField = "";
    formData.sortType = "";
  } else {
    formData.sortField = prop;
    formData.sortType = order === "descending" ? "desc" : "asc";
  }
  getList();
};
//点击采购订单详情
const checkGoods = (e) => {
  store.commit("PURCHASE_ORDER_INFO", e);
  orderShow.value = true;
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
  //获取仓库列表
const getStockList = () => {
  getStockListData({
    all: false,
    status: true
  }, (list) => {
    if (list.listLength === 1) {
      formData.stockId = list[0].stockId
    }
    getList()
  })
  }
onMounted(() => {
  getStockList()
  getTypeList()
});
defineExpose(formData)
</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "inventoryList",
  components: {
    Search,
    Plus,
    FieldsCheck,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.inventoryList {
  padding-top: 8px;
  height: calc(100% - 54px);
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .imgList {
    img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 5px;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }
      .more {
        color: #73737f;
      }
    }
    .statusBtn {
      display: flex;
      justify-content: center;
    }
    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;
      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}
</style>
