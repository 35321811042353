<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
    v-bind="$attrs"
    title="库存详情"
    width="920"
    class="goodInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDia"
    destroy-on-close
    align-center
    @open="openDia"
  >
      <el-scrollbar class="abcCheck_info" :style="{ height :`calc( ${height}px - 300px)`}" always>
        <div class="zh_content" v-loading="loading">
          <div class="info">
            <div class="zh_title">产品信息</div>
            <div class="zh_goods_info_content">
              <div class="zh_goods_info dp_f">
                <div class="zh_goods_img">
                  <img :src="goodsInfo.img" class="zh_pic" alt="位元灵感"/>
                </div>
                <div class="zh_goods">
                  <div class="zh_goods_name">{{ goodsInfo.productName }} <span class="zh_line">/</span> <span
                      class="zh_unit" v-html="getUnitName(goodsInfo)"></span></div>
                  <div class="zh_goods_item dp_f">
                    <div class="zh_goods_item_l">
                      <span class="zh_goods_title">产品品牌：</span>
                      <span class="zh_goods_text">{{ goodsInfo.brandName || '--' }}</span>
                    </div>
                    <div class="zh_goods_item_l">
                      <span class="zh_goods_title">规格/型号：</span>
                      <span class="zh_goods_text">{{ goodsInfo.modelName || '--' }}</span>
                    </div>
                  </div>
                  <div class="zh_goods_item dp_f">
                    <div class="zh_goods_item_l">
                      <span class="zh_goods_title">产品条码：</span>
                      <more-list :list='goodsInfo.encoded' v-if='goodsInfo.encoded'></more-list>
                      <span class="zh_goods_text" v-else>--</span>
                    </div>
                    <div class="zh_goods_item_l">
                      <span class="zh_goods_title">合计数量/金额(元)：</span>
                      <span class="zh_goods_text">{{ goodsInfo.totalNum || 0 }} / <span
                          class="unit">¥</span>{{ $filters.toYuan(goodsInfo.totalPrice, '元') }}</span>
                    </div>
                  </div>
                  <div class="zh_goods_item dp_f">
                    <div class="zh_goods_item_l">
                      <span class="zh_goods_title">预估销售周期：</span>
                      <span class="zh_goods_text"
                            v-if="goodsInfo.estimatedSalesCycle">{{ goodsInfo.estimatedSalesCycle }}天</span>
                      <span class="zh_goods_text" v-else>--</span>
                    </div>
                    <div class="zh_goods_item_l">
                    </div>
                  </div>
                </div>
              </div>
              <div class="zh_goods_btn_box">
                <el-button class="zh-btn" @click='handleSlowSales' v-if='goodsInfo.advent > 0'>
                  临期产品({{ goodsInfo.advent }})
                </el-button>
                <el-button class="zh-btn" @click='handleInventroy' v-if='goodsInfo.unsalable > 0'>
                  滞销产品({{ goodsInfo.unsalable }})
                </el-button>
              </div>
            </div>
            <div class="zh_title">详细信息</div>
            <el-tabs v-model="tabChecked" class="demo-tabs" @tab-change="tabCheck">
              <el-tab-pane :label="item.name" :name="item.checked" v-for='(item,index) in tabList' :key="index">
              </el-tab-pane>
            </el-tabs>
            <div class="query_box animate__fadeIn animate__animated">
              <div class="query_title">供应商</div>
              <el-select placeholder="请选择供应商" class="w240" v-model="formData.supplierId" @change="getRfidList">
                <el-option v-for="item in supplierList" :key="item.supplierId" :label="item.supplierName"
                           :value="item.supplierId"/>
              </el-select>
              <!--        <div class="query_title ml_16 "   v-if='tabChecked !="normal"'>异常状态</div>-->
              <!--        <el-select placeholder="请选择异常状态" class="w240 " @change="getRfidList" v-model="formData.statusId"  v-if='tabChecked !="normal"'>-->
              <!--          <el-option v-for="item in statusList" :key="item.statusId"  :label="item.statusName" :value="item.statusId"  />-->
              <!--        </el-select>-->
            </div>
            <div class="tabList" v-show='isStart '>
              <el-table
                  :data="tableData"
                  style="width: 100%"
                  class="zh-no-table-border"
                  :row-class-name="tableRowClassName"
                  :cell-style="{border:'none'}"
                  :header-row-style="{border:'none'}"
              >
                <el-table-column
                    align="center"
                    label="仓库名称"
                    width="200"
                    prop="stockName"
                    show-overflow-tooltip
                    class-name="table_text"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="货架编号"
                >
                  <template #default='{row}'>
                    <div class="table_text2">{{ row.childShelfName }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="库存数量"
                    width="200"
                >
                  <template #default='scope'>
                    <div class="table_text">
                      <span class="table_text">{{ scope.row.inventoryQuantity }}</span>
                      <span class="contBtn ml_8" @click='handleDetail(scope.row)'>查看明细</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="合计金额（元）"
                    width="130"
                >
                  <template #default='scope'>
                    {{ $filters.toYuan(scope.row.totalPrice, '元') }}
                  </template>
                </el-table-column>
                <template #empty>
                  <WyEmpty/>
                </template>
              </el-table>
            </div>
            <!-- <div class="mainPage">
         <el-pagination
           v-model:current-page="pageInfo.pageNumber"
           layout="total, prev, pager, next , jumper"
           :total="pageInfo.total"
           v-model:page-size="pageInfo.pageSize"
           @current-change="handleCurrentChange"
         />
       </div> -->
          </div>
        </div>
      </el-scrollbar>
  </el-dialog>
  </div>
  <Detail :goodsInfo='chooseRfid' :stock-type="stockType" :isError="isError" :stockId="chooseRfid.stockId || ''" v-model="showDetail"></Detail>
  <SlowSales :goodsInfo='slowDetail' :stock-type="stockType" alarmType='2' title="临期产品" :stockId="stockId" :child-shelf-id="childShelfId"
             value-string="临近过期时间/天" v-model="slowSalesShow"></SlowSales>
  <SlowSales :goodsInfo='slowDetail' :stock-type="stockType" alarmType='3' title="滞销产品" :stockId="stockId" value-string="在库时间（天）"
             :child-shelf-id="childShelfId" v-model="inventroyShow"></SlowSales>
</template>


<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import Detail from './detail.vue'
import SlowSales from './slowSales.vue'
import {stockAlarmDetail, stockDetail, stockInfo, stockSupplier} from '@/utils/api/inStock/inventory.js'
import {reactiveAssign, tableRowClassName} from '@/utils/tool.js'
import {computed, getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {useLoading} from "@utils/tool/loading";
import {useViewPort} from "@utils/tool/useVIewPort";
import WyEmpty from '@/components/element/empty/index.vue'
import {getUnitName} from "@utils/tool/orderTools";

const store = useStore()
const router = useRouter()
const props = defineProps({
  goodsInfo:{
    type:Object,
    default:()=>{}
  },
  childShelfId: {
    type: String,
    default: ''
  },
  stockType:{
    type: [String , Number],
    default: ''
  }
})
const {height} = useViewPort()
const {loading} = useLoading()
const {goodsInfo, childShelfId , stockType} = toRefs(props)
const inventroyShow = ref(false)
const slowSalesShow = ref(false)
const tabChecked = ref('normal')
const showDetail = ref(false)
const isStart = ref(true)

const tabCheck = (e) => {
  if (e === tabChecked.value) return
  isStart.value = false
  setTimeout(()=>{
    isStart.value = true
  },100)
}

//跳转到箱单关系页面
const { proxy } = getCurrentInstance()
const stockId = ref('')
const chooseRfid = reactive({})
const slowDetail = reactive({})
const isError = ref(false)
const tabList = ref([
  {
    name:'正常库存',
    checked: 'normal'
  },
  {
    name:'异常库存',
    checked:'temporary'
  },
])
const isTemporary = computed(() => {
  return tabChecked.value === 'temporary'
})
const getScattered = computed(() => {
  return goodsInfo.value.scattered ? 1 : 0
})
//供应商列表
const supplierList = ref([])
//观察tab切换
watch(()=>tabChecked.value,(val)=>{
  tableData.value = []
    //刷新列表
  getRfidList()
  isError.value = val !== 'normal';
})
const formData = reactive({
  supplierId:'',
  statusId:"0"
})
//请求列表接口
const getRfidList = ()=>{
  stockInfo({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    status: tabChecked.value === 'normal' ? 0 : 1,
    supplierId:formData.supplierId,
    stockId: stockId.value,
    scattered: getScattered.value,
    childShelfId: childShelfId.value,
    stockType: stockType.value,
  }).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    let list = res.data?.list || []
    list.forEach(item => {
      item.status = tabChecked.value === 'normal' ? 0 : 1
    });
    tableData.value = res.data?.list || []
  })
}

function changeList(obj) {
  let result = [];
  if (obj.child) {
    result = result.concat(changeList(obj.child));
  }
  result.push(obj);
  return result;
}
//获取库存箱单管理列表

//根据产品获取供应商
const getStockList = ()=>{
  stockSupplier({
    productId:goodsInfo.value.productId,
    scattered: goodsInfo.value.scattered
  }).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data?.list?.length === 1) {
      formData.supplierId = res.data.list[0].supplierId
      supplierList.value = res.data.list
    }else if(res.data?.list?.length  && res.data?.list?.length > 1){
      supplierList.value.push({supplierId:'',supplierName:'全部供应商'})
      supplierList.value.push(...res.data.list)
    }
  })
}
const handleDetail =async (e)=>{
  reactiveAssign(chooseRfid,e)
  await getGoodsDetail(e)
}
const handleInventroy = ()=>{
  stockAlarmDetail({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    alarmType: 3,
    scattered: getScattered.value,
    childShelfId: childShelfId.value,
    stockId: stockId.value,
    stockType: stockType.value
  }).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    reactiveAssign(slowDetail, {...res.data, scattered: getScattered.value, childShelfId: childShelfId.value})
    inventroyShow.value = true
  })
}
const handleSlowSales = ()=>{
    stockAlarmDetail({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
      alarmType: 2,
      scattered: getScattered.value,
      childShelfId: childShelfId.value,
      stockId: stockId.value,
      stockType: stockType.value
  }).then(res=>{
      if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
      reactiveAssign(slowDetail, {...res.data, scattered: getScattered.value, childShelfId: childShelfId.value})
     slowSalesShow.value = true
  })
}
// 获取产品详情
const getGoodsDetail = (e)=>{
  chooseRfid.status = e.status
  chooseRfid.scattered = getScattered.value
  stockDetail({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    scattered: goodsInfo.value.scattered,
    price: goodsInfo.value.scattered ? '' : e.price,
    childShelfId: e.childShelfId,
    stockType: stockType.value,
  }).then((res)=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    Object.assign(chooseRfid, res.data, {
      scattered: getScattered.value,
      totalNum: e.inventoryQuantity || 0,
      totalPrice: e.totalPrice || 0,
      childShelfId: e.childShelfId,
    })
    showDetail.value = true
  })
}
//弹窗打开产品列表接口
const openDia = ()=>{
  stockId.value= proxy.$parent.stockId
  let list = []
  if(!goodsInfo.value.abnormalStock){
    list = [
      {
        name:`正常库存(${goodsInfo.value.normalStock})`,
        checked: 'normal'
      }
    ]
  }else {
    list = [
      {
        name:`正常库存(${goodsInfo.value.normalStock})`,
        checked: 'normal'
      },
      {
        name:`异常库存(${goodsInfo.value.abnormalStock})`,
        checked:'temporary'
      },
    ]
    if(stockType.value === 1){
    //正常库存
      tabChecked.value = 'normal'
    }else if(stockType.value === 2){
      //异常库存
      tabChecked.value = 'temporary'
    }
  }
  tabList.value = list
  getRfidList()
  getStockList()
}

const emit = defineEmits(['handleClose'])

const tableData = ref([])

const closeDia = (done)=>{
  done()
  tabChecked.value = 'normal'
  formData.supplierId = '';
  formData.statusId="0";
  supplierList.value = []
}


</script>
<script>
import { reactive, toRefs, ref, watch,getCurrentInstance } from "vue";
import { ArrowDown } from '@element-plus/icons-vue'
export default {
  components: {
    ArrowDown,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content{
    padding: 16px;
    box-sizing: border-box;
    position: relative;
    .zh_goods_info_content{
       border-radius: 8px;
      background-color: #FAFBFC;
      padding: 24px;
      margin-top: 16px;
      margin-bottom: 24px;
      .zh_goods_btn_box{
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .zh_goods_info{

      .zh_goods_img{
        width: 144px;
        height: 144px;
        border-radius: 8px;
        overflow: hidden;
        .zh_pic{
          width: 100%;
          height: 100%;
        }
      }
      .zh_goods{
        width: 624px;
        color: $fsColor;
        .zh_goods_name{
          margin: 25px 0;
          line-height: 1;
          font-size: 22px;
          .zh_line{
            display: inline-block;
            margin: 0 6px;
            font-size: 18px;
          }
          .zh_unit{
            font-size: 18px;
          }
        }
        .zh_goods_item{
          margin-bottom: 16px;
          .zh_goods_item_l{
            display: flex;
            align-items: center;
            flex: 1;
            flex-wrap: nowrap;
            line-height: 1.3;
            >span{
              display: inline-block;
            }
            .zh_goods_title{
              color: #737480;
            }
            .zh_goods_text{
              @include textOverflow(160px);
              .unit{
                display: inline-block;
                margin-right:2px;
              }
            }
          }
        }
      }
    }
    .zh_introduce{
      margin-top: 0;
      flex-wrap: wrap;
      padding-top: 16px;
      height: auto;
      .item{
        width:max-content;
        margin-right: 40px;
        margin-bottom: 16px;
      }
    }
    .demo-tabs{
      margin-top: 14px;
    }
    .query_box{
      display: flex;
      align-items: center;
      .query_title{
        margin-right: 8px;
        font-size: 14px;
        color: #737480;
      }
    }
    .tabList{
    padding-bottom: 16px;
    box-sizing: border-box;
    .name{
      color: #73737F;
    }
    .text2{
      color: $fsColor;
    }
  }
  }
}

</style>
