import {ref} from 'vue';
import {cloneDeep} from "lodash";

export default function (columnsStock) {
    const customFields = ref(false);
    const titleList = ref(cloneDeep(columnsStock));
    const fieldCheckShow = () => {
        customFields.value = true;
    };
    const checkFileds = () => {
        customFields.value = false;
    };
    return {
        customFields,
        fieldCheckShow,
        checkFileds,
        titleList
    }
}