<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
      v-bind="$attrs"
      title="效期预警规则配置"
      width="1400"
      class="goodInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openDetail"
      @close="close"
  >
    <div class="zh_content" v-loading="loading">
      <div class="info">
        <div class="zh_title">
          <span class="red">*</span><span class="sub-text">选择仓库</span>
          <el-select v-model="stockId" placeholder="请选择仓库" class="w200 mr_32 ml_8" :class="[isErr?'err':'']">
            <el-option v-for="item in stockList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
          </el-select>
          <template v-if="stockId">
            不选择产品，仓库产品统一配置效期预警时间(单独配置的产品除外)：
            <el-input v-elInput class="w120 configInput" placeholder="默认60天" maxlength="3"
                      :class="[isDayErr?'err':'']"
                      v-model="timeValue"
                      v-number
                      @blur="timeValue = $event.target.value"></el-input>
            天
          </template>
        </div>
        <div class="zh_title mt_64 dp_f" v-if="stockId">
          <div class="l">
            单独配置产品效期预警时间: <span class="sub">（针对产品单独配置，则按照产品单独配置的规则进行预警）</span>
          </div>
          <div class="r">
            <el-button type="primary" @click='openAdd'>批量添加</el-button>
          </div>
        </div>
        <div class="tabList">
          <el-table :data="tableData" style="width: 100%" height="96%" class="zh-no-table-border"
                    :row-class-name="tableRowClassName"
                    :cell-style="{border:'none'}"
                    :header-row-style="{border:'none'}" v-if="stockId">
            <el-table-column
                type="index"
                width="100"
                label="序号"
                align="center"
                fixed
            />
            <el-table-column width="254" align="center">
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                <div class="table_text2 tableName160" v-if="scope.row.productId">
                  {{ scope.row.productName }}
                </div>
                <SelectGoodsFromList v-model:goods="tableData[scope.$index]"   @change="pushGoods" followTime :propList='tableData' v-else></SelectGoodsFromList>
              </template>
            </el-table-column>
            <el-table-column width="130" align="center" class-name="table_text2" label="简称" show-overflow-tooltip>
              <template #default="scope">
                {{ scope.row.subName }}
              </template>
            </el-table-column>
            <el-table-column width="160" align="center" label="产品品牌"  class-name="table_text2" show-overflow-tooltip>
              <template #default="scope">
                <template v-if='scope.row.productId'>
                  {{ scope.row.brandName }}
                </template>
              </template>
            </el-table-column>
            <el-table-column width="170" align="center" label="产品单位" class-name="table_text2" show-overflow-tooltip>
              <template #default="scope">
                {{ scope.row.unitName }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" label="规格/型号"  class-name="table_text2" show-overflow-tooltip>
              <template #default="scope">
                {{ scope.row.modelName }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="center" label="剩余过期时间（天）"  class-name="table_text2" show-overflow-tooltip>
              <template #default="scope" >
<!--                <div class="table_text2" v-if='!scope.row.isUpdate && scope.$index != (tableData.length - 1)'>{{scope.row.ruleValue || 0}}</div>-->
                <div class="table_text2" >
                  <el-input class="w120" :class="[scope.row.isErr ? 'err' : '']" placeholder="请填写天数" maxlength="10"
                            v-model="tableData[scope.$index].ruleValue" v-number
                            v-elInput
                            @blur="tableData[scope.$index].ruleValue = $event.target.value"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" width="120" fixed="right" label="操作" >
              <template #default='scope'>
                <span class="contBtn mr_10" @click='delItem(scope.$index)'
                      v-if="scope.$index !== (tableData.length - 1)">移除</span>
<!--                <span class="contBtn mr_10" @click='handleUpdate(scope.$index,true)' v-if='scope.$index != (tableData.length - 1) && !scope.row.isUpdate'>编辑</span>-->
                <span class="contBtn" @click='handleUpdate(scope.$index,false)'
                      v-if='scope.$index !== (tableData.length - 1)  && scope.row.isUpdate'>确认</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="remark" >
          <div class="btnBox">
            <copy-rule :stock-id="stockId" @save="saveRule" :can-confirm="canConfirm" :stock-list="stockList"
                       v-if="stockId" :alarmType="alarmType"></copy-rule>
            <!--            <el-button class="zh-btn btn1 info_btn" @click='close'>取消</el-button>-->
            <el-button class="btn1 info_btn" type="primary" :disabled="!canConfirm" @click='saveRule'>保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <Bind v-model="bindDia" title='批量添加' followTime :propList='tableData'  @close='closeAdd' @save='moreAddOk'></Bind>
</template>
<script setup>
import CopyRule from "@views/inStock/inventoryCheck/components/component/copyRule.vue";
import Bind from '@/components/bind/bind.vue';
import {tableRowClassName} from '@/utils/tool.js';
import {computed, getCurrentInstance, reactive, ref, toRefs, watch,} from "vue";
import {cloneDeep} from "lodash";
import {stockRuleCopy, stockRuleList, stockRuleSave} from '@/utils/api/inStock/inventory.js'
import {checkErrList} from "@utils/tool/checkList";

import {checkErr} from '@/utils/tool/isErr.js';

const {isErr, setErr} = checkErr({
  msg: '请先选择仓库'
});

const dayErr = checkErr({
  msg: '请填写必填选项'
})

const {isErr: isDayErr, setErr: setDayErr} = dayErr

const { proxy } = getCurrentInstance();
const emit = defineEmits(['close'])
const pushGoods = (e)=>{
  tableData.value.push({})
}
const alarmType = 2
const props = defineProps({
  stockList: {
    type: Array,
    default: () => [],
  },
})
const loading = ref(false)
const stockId = ref('')
const {stockList} = toRefs(props)
//打开批量添加弹窗
const openAdd = () => {
  bindDia.value = true
}
const bindDia = ref(false)
const closeAdd =()=>{
  bindDia.value = false
}
const tableData = ref([{}]);
const timeValue = ref('')
const openDetail = () => {
};
watch(stockId, (newVal) => {
  if (newVal) {
    getConfigInfo()
  }
})
const getConfigInfo = ()=>{
  loading.value = true
  stockRuleList({alarmType, stockId: stockId.value}).then(res => {
    if (res.code === 0) {
      tableData.value = res.data.list || []
      tableData.value.push({})
      timeValue.value = res.data.census
    }else{
      proxy.$message.error(res.msg)
    }
    loading.value = false
  })
}
//移除
const delItem=(index)=>{
  tableData.value.splice(index,1)
}
//编辑
const handleUpdate = (index,isUpdate)=>{
  tableData.value[index].isUpdate = isUpdate
}
//确认是否可提交
const canConfirm = computed(()=>{
  return true
  // return stockId.value && timeValue.value !== '' && timeValue.value !== "0" && tableData.value.every((item, index) => item.productId ? item.ruleValue && item.ruleValue > 0 : true)
})

//保存批量添加
const moreAddOk = (e) => {
  tableData.value.pop()
  tableData.value = tableData.value.concat(e)
  tableData.value.push({})
}
const saveRule = (e) => {
  if (!stockId.value) {
    setErr()
    return
  }
  if (!timeValue.value || timeValue.value === '0') {
    setDayErr()
    return
  }
  if (checkErrList(tableData.value, 'ruleValue', true)) return
  let list = cloneDeep(tableData.value)
  list.pop()
  stockRuleSave({
    alarmType,
    list:list,
    stockId: stockId.value,
    census:Number(timeValue.value)
  }).then((res)=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (e?.alarmType) {
      handleCopyRule(e)
      return
    }
    proxy.$message.success('保存成功')
  })
}
const handleCopyRule = (e) => {
  stockRuleCopy(e).then(res => {
    if (res.code === 0) {
      proxy.$message.success('预警规则同步成功')
    } else {
      proxy.$message.error(res.msg)
    }
  })
}
const close = ()=>{
  proxy.$emit('close')
  reset()
}

function reset() {
  stockId.value = ''
  tableData.value = []
  timeValue.value = ''
}

</script>

<script>
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "oredrInfo",
  components: {
    ArrowDown,
    SelectByAbcModal,
    SelectGoodsFromList
  },
};
</script>
<style lang="scss" src="./config.scss" scoped>
</style>
