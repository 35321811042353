export const tagsColumn = [
    {
        title: "标签序列表",
        fieldKey: "serial",
    },
    {
        title: "产品名称",
        fieldKey: "productName",
    },
    {
        title: "简称",
        fieldKey: "subName",
    },
    {
        title: "产品条码",
        fieldKey: "barcode",
    },
    {
        title: "产品单位",
        fieldKey: "unitName",
    },
    {
        title: "规格/型号",
        fieldKey: "modelName",
    }, {
        title: "产品品牌",
        fieldKey: "brandName",
    }, {

        title: "产品分类",
        fieldKey: "classifyName",
    }, {
        title: "库存数量",
        fieldKey: "count",
    },
    {
        title: "过期时间",
        fieldKey: "outTime",
    },
    {
        title: "所属仓库",
        fieldKey: "stockName",
    },
    {
        title: "所属货架",
        fieldKey: "shelfName",
    },
    {
        title: "所属货位",
        fieldKey: "childShelfName",
    },
    {
        title: "入库时间",
        fieldKey: "inStockTime",
    },
    {
        title: "入库金额",
        fieldKey: "inStockPrice",
    },
     {
        title: "备注",
        fieldKey: "remark",
    }
]