<template>
  <el-dialog
    v-bind="attrs"
    class="customerDia"
    :align-center='true'
    width="920"
    :close-on-click-modal="false"
    destroy-on-close
    @open="getGoodsList"
    @close="closeDia"
  >
   <div class="content">
      <div class="queryList">
        <div class="item">
          <div class="query_name">产品名称</div>
          <el-input
            v-elInput
            class="w240"
            placeholder="请输入产品名称"
            v-model="formData.query"
            @keyup.enter="getGoodsList"
          ></el-input>
        </div>
        <div class="item">
          <div class="query_name">产品品牌</div>
            <SelectByAbcModal placeholder="选择品牌" typeName="brand" :maxNum="1" v-model:list="formData.brand" className="w240 mr_16" @change="getGoodsList"></SelectByAbcModal>
        </div>
        <div class="item">
          <el-button type="primary" class="searchBtn" @click="getGoodsList">
            <el-icon><Search /></el-icon>
          </el-button>
        </div>
      </div>
      <div class="tagsList" v-if="diaList.length > 0">
        <el-tag
          v-for="tag in diaList"
          :key="tag.modelId"
          class="tag1"
          closable
          @close="delDiaListItem(tag)"
        >
          {{ tag.productName }}
        </el-tag>
      </div>
      <div class="tagsList" v-else>
        <div class="text">暂无添加</div>
      </div>
      <div class="search_table">

            <el-table
            :data="tableData"
            style="width: 100%"
            height="464"
            class="zh-no-table-border"
            :row-class-name="tableRowClassName"
            :cell-style="{ border: 'none' }"
            :header-row-style="{ border: 'none' }"
          >
            <el-table-column
              align="center"
              label="序号"
              width="70"
            >
              <template #default="scope">
                <div class="table_text">{{scope.$index + 1}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品名称"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2">{{scope.row.productName}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="简称"
              width="70"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text">{{scope.row.subName}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品品牌"
              width="150"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2">{{scope.row.brandName}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品单位"
              width="140"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2">{{scope.row.unitName}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="规格/型号"
              width="140"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2">{{scope.row.modelName}}</div>
              </template>
            </el-table-column>
            <el-table-column
            prop="model"
            label="操作"
            align="center"
             width="100"
          >
              <template #default="scope">
              <div class="contBtn"  @click='handleSelectionChange(scope.row)'>{{isIn(scope.row)?'已添加':'添加'}}</div>
            </template>
          </el-table-column>
          </el-table>
      </div>
    </div>
    <template #footer>
      <el-button @click='closeDia' class="btn1 zh-btn">取消</el-button>
      <el-button class="main btn1" :disabled="!diaList.length" type="primary" @click='saveDiaList'
        >保存</el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
//引入vue
import {
  ref,
  reactive,
  getCurrentInstance,
  watch,
  useAttrs,
  defineProps,
  defineEmits,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import {
  productSearch,
} from "@/utils/api/product/product.js";
//引入reactive对象重置
//设置模型
import { mergeList, tableRowClassName} from "@/utils/tool.js";
//引入列表表头配置文件
const { proxy } = getCurrentInstance();
const attrs = useAttrs();
const tabChecked = ref(0)
//未绑定产品列表
const tableData = ref([])
const props = defineProps({
  propList:{
    type:Array,
    default:()=>[]
  },
  followTime:{
    type:Boolean,
    default:false
  },
});
const emits = defineEmits(["close", "save"]);
const {propList,followTime} = toRefs(props)
const formData = reactive({
  query: "",
  brandName: "",
  brandId: "",
  brand: [],
})
const diaList = ref([])

//删除绑定产品
const delDiaListItem = (e)=>{
  let index = diaList.value.findIndex((item)=>item.modelId == e.modelId)
  diaList.value.splice(index,1)
  tableData.value.push(e)
}

//添加绑定商品
const handleSelectionChange = (e)=>{
  if(!isIn(e)){
    diaList.value.push(e)
    let index = tableData.value.findIndex(item=>item.modelId == e.modelId)
    tableData.value.splice(index,1)
  }
}


//查询产品列表
function getGoodsList(){
  productSearch({
    query:formData.query,
    brandId:formData.brand[0]?.brandId || '',
    followTime:followTime.value
  }).then(res=>{
    if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = mergeList(res?.data?.list || [] , propList.value, 'modelId')
      tableData.value =mergeList(list , diaList.value, 'modelId')
  })
}


//判断批量添加是否已添加到列表
const isIn = (e)=>{
  let flag = false;
  diaList.value.forEach((item)=>{
    if(item.modelId == e.modelId){
      flag = true;
    }
  })
  return flag;
}

//确认保存列表
const saveDiaList = ()=>{
  emits('save',diaList.value)
  emits('close')
}


//取消弹窗
const closeDia = ()=>{
    proxy.$emit('close')
    diaList.value = []
    formData.query = ''
    formData.brandName = ''
    formData.brandId = ''
    formData.brand = []
    tableData.value = []
}
</script>
<script>
import { ref, getCurrentInstance, watch, useAttrs } from "vue";
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import { Search } from "@element-plus/icons-vue";
export default {
  name: "customerDia",
  components: {
    Search,
    SelectByAbcModal
  },
};
</script>
<style lang="scss">
@import "./bind.scss";
</style>
