<template>
  <el-button v-bind="attrs" class="btn1 info_btn mr_16 zh-btn" :disabled="!stockId || !canConfirm" @click="handleCopy">
    预警规则同步
  </el-button>
  <el-dialog
      v-model="showCopy"
      v-bind="attrs"
      title="同步仓库"
      width="600"
      class="goodInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openDetail"
      @close="close"
  >
    <div class="fieldsContent">
      <el-checkbox-group v-model="checkList">
        <template v-for='item in stockList' :key='item.stockId'>
          <el-checkbox :label="item" v-if="item.stockId !== stockId">{{ item.stockName }}
          </el-checkbox>
        </template>
      </el-checkbox-group>
    </div>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button class="main" type="primary" @click="handleOk">
        保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs, useAttrs} from "vue";

const props = defineProps({
  stockId: {
    type: [String, Number],
    default: "",
  },
  alarmType: {
    type: Number,
    default: 0
  },
  stockList: {
    type: Array,
    default: () => []
  },
  canConfirm: {
    type: Boolean,
    default: false
  }
})
const checkList = ref([])
const {stockId, alarmType, stockList, canConfirm} = toRefs(props)
const {proxy} = getCurrentInstance();
const attrs = useAttrs();
const showCopy = ref(false);
const emit = defineEmits(['save'])
const openDetail = () => {
  showCopy.value = true;
};
const close = () => {
  showCopy.value = false
  reset()
}

function reset() {
  checkList.value = []
}

const handleOk = () => {
  if (!checkList.value.length) {
    proxy.$message.error('请选择至少一个仓库')
    return
  }
  let list = checkList.value.map(item => item.stockId).toString()
  let obj = {
    alarmType: alarmType.value,
    copyStockId: stockId.value,
    stockId: list
  }
  emit('save', obj)
  showCopy.value = false;

}
const handleCopy = () => {

  showCopy.value = true;
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "CopyRule"
});
</script>

<style lang="scss" scoped>

</style>
