import request from '../http'

//货架列表
export function getShelfList(data) {
    return request({
        url: "/carton/product/stock/shelf/list",
        method: 'post',
        data
    })
}

//新增货架
export function addShelf(data) {
    return request({
        url: "/carton/product/stock/shelf/add",
        method: 'post',
        data
    })
}

//修改货架状态
export function updateShelfStatus(data) {
    return request({
        url: "/carton/product/stock/shelf/changeStatus",
        method: 'post',
        data
    })
}

//货架详情
export function getShelfDetail(data) {
    return request({
        url: "/carton/product/stock/shelf/detail",
        method: 'post',
        data
    })
}

//编辑货架
export function updateShelf(data) {
    return request({
        url: "/carton/product/stock/shelf/edit",
        method: 'post',
        data
    })
}

//货位查询
export function getShelfPosition(data) {
    return request({
        url: "/carton/product/stock/shelf/query",
        method: 'post',
        data
    })
}