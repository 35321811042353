import {ref} from 'vue';
import {typeInfo} from "@utils/api/type";
import {removeNoChild} from "@utils/tool";
import message from "@utils/tool/message";

const candiyConfig = {
    expandTrigger: "hover",
    value: "classifyId",
    label: "classifyName",
    children: "childList",
    checkStrictly: true,
};

export default function () {
    const typeOptions = ref([])
    const getTypeList = async () => {
        let res = await typeInfo();
        if (res.code !== 0) {
            message.error(res.msg);
            return;
        }
        let list = res.data?.list || []
        //排除掉list 里面不包含childList的数据
        list = removeNoChild(list);
        typeOptions.value = list;
    };
    return {
        typeOptions,
        getTypeList,
        candiyConfig
    }
}