import {getCurrentInstance, onUnmounted, ref} from 'vue'

export function checkErr(obj = {
  msg:'请先填写必填选项'
}) {
  const { proxy } = getCurrentInstance();
  let timer = null
  const isErr = ref(false)
  function setErr(){
    if(!isErr.value){
      proxy.$message.error(obj.msg);
      isErr.value = true
    }
    if(timer) clearTimeout(timer)
    timer = setTimeout(()=>{
      isErr.value = false
      proxy.$message.close()
    }, 1000)
  }
  onUnmounted(()=>{
    if(timer) clearTimeout(timer)
    proxy.$message.close()
  })
  return {
    setErr,
    isErr
  }
}

