<template>
  <div class="inventoryCheck">
    <div class="content">
      <el-tabs v-model="tabChecked" @tab-click="tabCheck" class="zh-tabs">
        <el-tab-pane :label="item.name" :name="item.checked" v-for='(item,index) in tabList' :key="index">
        </el-tab-pane>
      </el-tabs>
      <component :is='tabList[currentIndex].components' :key="tabList[currentIndex].name"></component>
    </div>
  </div>
</template>

<script>
import {computed, getCurrentInstance, onMounted, reactive, ref, shallowRef, toRefs} from 'vue'
import InventoryList from './components/inventoryList.vue'
import InventoryWarning from './components/inventoryWarnings.vue'
import SlowSalesWarning from './components/slowSalesWarning.vue'
import ValidityWarning from './components/validityWarning.vue'
import {useRoute} from "vue-router";

export default {
  name:"order",
  components:{
    InventoryList,
    InventoryWarning,
    SlowSalesWarning,
    ValidityWarning
  },
  setup () {
    const { proxy} = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      count: 0,
      tabChecked:"inventoryList",
      countInfo:{
        waitStock:0,
        inStock:0,
      }
    })
    const tabList = ref([
      {
        name:'库存列表',
        checked:'inventoryList',
        components: shallowRef(InventoryList)
      },
      {
        name:'库存预警',
        checked:'InventoryWarning',
        components: shallowRef(InventoryWarning)
      },
      {
        name:'效期预警',
        checked:'validityWarning',
        components: shallowRef(SlowSalesWarning)
      },
      {
        name:'滞销库存',
        checked:'slowSalesWarning',
        components: shallowRef(ValidityWarning)
      },
    ])
    const tabCheck = function(tab,e){
    }
    const currentIndex = computed(()=>{
      return tabList.value.findIndex(item=>item.checked === state.tabChecked)
    })
    onMounted(()=>{
      if(route.query && route.query.tabChecked){
        state.tabChecked = route.query.tabChecked
      }
    })
    return {
      ...toRefs(state),
      tabCheck,
      tabList,
      currentIndex
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
  .inventoryCheck{
    height: 100%;
    padding:16px;
    box-sizing: border-box;
    .el-tabs__header{
      margin-bottom: 0;
    }
    >.content{
        border-radius: 16px;
        background: #fff;
        padding: 12px 32px;
        height: 100%;
      //box-sizing: border-box;
        position: relative;
    }
  }
</style>
